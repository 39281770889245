import {
  // faTachometerAlt,
  faFileAlt,
  faStream,
  faPlus,
  faFileUpload,
  // faWarehouse,
  // faPeopleCarry,
  // faTruck,
  // faTimesCircle,
  // faClipboardCheck,
  // faReply,
  // faBan,
  // faTruckLoading,
  // faCheckDouble,
  // faTimes,
  // faReplyAll,
  // faRetweet,
  faBoxes,
  faTrash,
  faFileInvoiceDollar,
  // faWrench,
  // faUser,
  // faUserPlus,
  // faUsers,
  // faLayerGroup,
  // faRedo,
  // faTruckMoving,
  // faMoneyBill,
  // faChartBar,
  // faCashRegister,
  // faPiggyBank,
  // faDesktop,
  // faUserCheck,
  // faClipboardList,
  // faPallet,
  // faBoxes,
  // faMapMarkedAlt,
  faCheck,
  faWrench,
  // faShippingFast,
} from '@fortawesome/free-solid-svg-icons';
import roles from '../services/roles';

export default {
  // Dashboard: {
  //   roles: roles.dashboard,
  //   routes: [
  //     {
  //       roles: roles.dashboard,
  //       key: "/",
  //       text: "Dashboard",
  //       icon: faTachometerAlt,
  //       path: "/"
  //     },
  //     {
  //       roles: roles.dashboard,
  //       key: "/kpi",
  //       text: "KPI Dashboard",
  //       icon: faTachometerAlt,
  //       path: "/kpi"
  //     }
  //   ]
  // },
  Package: {
    roles: roles.all,
    routes: [
      {
        roles: roles.all,
        key: 'waybill',
        text: 'Waybill',
        icon: faFileAlt,
        items: [
          {
            roles: roles.all,
            key: '/waybill/list',
            text: 'List',
            icon: faStream,
            path: '/waybill/list',
          },
          {
            roles: roles.all,
            key: '/waybill/create',
            text: 'Create',
            icon: faPlus,
            path: '/waybill/create',
          },
          {
            roles: roles.all,
            key: '/waybill/upload',
            text: 'Upload Booking',
            icon: faFileUpload,
            path: '/waybill/upload',
          },
          {
            roles: roles.all,
            key: '/void-waybill',
            text: 'Void Waybill',
            icon: faTrash,
            path: '/void-waybill',
          },
        ],
      },
      {
        roles: roles.all,
        key: '/tools',
        text: 'Tools',
        icon: faWrench,
        items: [
          {
            roles: roles.all,
            key: '/tools/package-group-checker',
            text: 'Group Checker',
            icon: faCheck,
            path: '/tools/package-group-checker',
          },
          {
            roles: roles.all,
            key: 'dimweight',
            text: 'Dimweight Upload',
            icon: faFileUpload,
            path: '/dimweight',
          },
        ],
      },
      {
        roles: roles.all,
        key: '/merchant-pickup',
        text: 'First Mile',
        icon: faWrench,
        items: [
          // {
          //   roles: roles.all,
          //   key: '/merchant-pickup/list',
          //   text: 'Group Checker',
          //   icon: faStream,
          //   path: '/merchant-pickup/list',
          // },
          {
            roles: roles.all,
            key: '/merchant-pickup/selector',
            text: 'Pickup Selector',
            icon: faStream,
            path: '/merchant-pickup/selector',
          },
        ],
      },
    ],
  },
  Items: {
    roles: roles.all,
    routes: [
      {
        roles: roles.all,
        key: 'items',
        text: 'Item List',
        icon: faBoxes,
        path: '/items',
      },
      {
        roles: roles.all,
        key: '/barcode',
        text: 'Barcode Generator',
        icon: faFileInvoiceDollar,
        path: '/barcode',
      },
    ],
  },
};
