import React, { Component } from 'react';
import Table from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

class ReactTable extends Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.columns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'target_status',
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        accessor: 'options',
      },
    ];
  }

  render() {
    const {
      columns, data, pages, onFetchData, loadingText, loading,
    } = this.props;

    return (
      <Table
        filterable
        sortable
        manual
        getTdProps={() => ({
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
        })}
        data={data}
        pages={pages}
        columns={columns || this.columns}
        loading={loading}
        loadingText={loadingText}
        className="-highlight"
        defaultPageSize={10}
        onFetchData={onFetchData}
      />
    );
  }
}

ReactTable.defaultProps = {
  data: [],
  pages: 1,
  loadingText: null,
  loading: false,
};

ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.number,
  onFetchData: PropTypes.func.isRequired,
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ReactTable;
