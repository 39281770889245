import {
  apiFetchMerchantPickupTrackingNumber,
  apiCreateMerchantPickup,
  apiUpdateMerchantPickup,
  apiFetchMerchantPickupDetails,
  apiFetchMultiMerchantPickupTrackingNumber,
  apiSearchMerchant,
  apiGetPickupDetails,
  apiGetManifestList,
} from '../../services/apiEndpoints';

const SEARCH_MERCHANT = 'merchantPickup/SEARCH_MERCHANT';
export const searchMerchant = filter => ({
  type: SEARCH_MERCHANT,
  payload: {
    client: 'secure',
    request: {
      ...apiSearchMerchant(filter),
    },
  },
});

const GET_PICKUP_DETAILS = 'merchantPickup/GET_PICKUP_DETAILS';
export const getPickupDetails = (shipperID, filter) => ({
  type: GET_PICKUP_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetPickupDetails(shipperID, filter),
      // data: {
      //   shipper_id: shipperID,
      // },
    },
  },
});

const GET_MANIFEST_LIST = 'merchantPickup/GET_MANIFEST_LIST';
export const getManifestList = phID => ({
  type: GET_MANIFEST_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiGetManifestList,
      data: {
        ph_id: phID,
      },
    },
  },
});

const FETCH_MERCHANT_PICKUP_TRACKING_NUMBER = 'merchantPickup/FETCH_MERCHANT_PICKUP_TRACKING_NUMBER';
export const fetchMerchantPickupTrackingNumber = (shipperId, trackingNumber) => ({
  type: FETCH_MERCHANT_PICKUP_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchMerchantPickupTrackingNumber(trackingNumber),
      params: {
        shipper_id: shipperId,
      },
    },
  },
});

const FETCH_MULTI_MERCHANT_PICKUP_TRACKING_NUMBER = 'merchantPickup/FETCH_MULTI_MERCHANT_PICKUP_TRACKING_NUMBER';
export const fetchMultiMerchantPickupTrackingNumber = trackingNumber => ({
  type: FETCH_MULTI_MERCHANT_PICKUP_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: apiFetchMultiMerchantPickupTrackingNumber(trackingNumber),
  },
});
  
const CREATE_MERCHANT_PICKUP = 'merchantPickup/CREATE_MERCHANT_PICKUP';
export const createMerchantPickup = data => ({
  type: CREATE_MERCHANT_PICKUP,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateMerchantPickup,
      data,
    },
  },
});

const UPDATE_MERCHANT_PICKUP = 'merchantPickup/UPDATE_MERCHANT_PICKUP';
export const updateMerchantPickup = (pickupId, data) => ({
  type: UPDATE_MERCHANT_PICKUP,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateMerchantPickup(pickupId),
      data,
    },
  },
});

const FETCH_MERCHANT_PICKUP_DETAILS = 'merchantPickup/FETCH_MERCHANT_PICKUP_DETAILS';
export const fetchMerchantPickupDetails = pickupId => ({
  type: FETCH_MERCHANT_PICKUP_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchMerchantPickupDetails(pickupId),
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
