import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Icon, Form, Tag, Descriptions, Result,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import { itemfetchviews } from './ducks';

export class ItemListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataforView: ' ',
      history: [],
    };
  }

  componentDidMount() {
    const { doItemfetchviews, match, history } = this.props;
    doItemfetchviews(match.params.reference, match.params.item_id).then((res) => {
      // if (res.payload.data.details.client_name !== 'Avon Philippines') {
      //   history.push('/404');
      // } else {
      this.setState({
        dataforView: res.payload.data.details,
        history: res.payload.data.history,
      });
      // }
    }).catch(() => {
      history.push('/404');
    });
  }

  render() {
    const { dataforView, history } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Items',
      },
      {
        breadcrumbName: 'Item View',
      },
    ];

    const itemListColumns = [
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Reference Code',
        accessor: 'reference_code',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
      {
        Header: 'Created By',
        accessor: 'user',
      },
    ];

    return (
      <div className="ItemListView">
        <PageHeader title="Item View" routes={breadCrumbs} />
        {dataforView ? (
          <Container>
            <Card>
              <NavLink to="/items">
                <Button type="primary">
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
                  </Icon>
                  Back to List
                </Button>
              </NavLink>
              <Spacer />
              <Descriptions bordered size="small">
                <Descriptions.Item label="Tracking Number: "><Tag color="orange"><NavLink to={`/waybill/${dataforView ? dataforView.tracking_number : ' '}`}><Button size="small" type="link">{dataforView ? dataforView.tracking_number : ' '}</Button></NavLink></Tag></Descriptions.Item>
                <Descriptions.Item label="Item ID: ">{dataforView ? dataforView.item_id : ' '}</Descriptions.Item>
                <Descriptions.Item label="Reference: ">{dataforView ? dataforView.reference : ' '}</Descriptions.Item>
                <Descriptions.Item label="Description: ">{dataforView ? dataforView.description : ' '}</Descriptions.Item>
                <Descriptions.Item label="Quantity: ">{dataforView ? dataforView.quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="UOM: ">{dataforView ? dataforView.uom : ' '}</Descriptions.Item>
                <Descriptions.Item label="Length: ">{dataforView ? dataforView.length : ' '}</Descriptions.Item>
                <Descriptions.Item label="Width: ">{dataforView ? dataforView.width : ' '}</Descriptions.Item>
                <Descriptions.Item label="Height: ">{dataforView ? dataforView.height : ' '}</Descriptions.Item>
                <Descriptions.Item label="Weight: ">{dataforView ? dataforView.weight : ' '}</Descriptions.Item>
                <Descriptions.Item label="Volume: ">{dataforView ? dataforView.volume : ' '}</Descriptions.Item>
                <Descriptions.Item label="Value: ">{dataforView ? dataforView.value : ' '}</Descriptions.Item>
                <Descriptions.Item label="Comment: ">{dataforView ? dataforView.comment : ' '}</Descriptions.Item>
                <Descriptions.Item label="Status: "><Tag color="orange">{dataforView ? dataforView.status : ' '}</Tag></Descriptions.Item>
                <Descriptions.Item label="Current Location: ">{dataforView ? dataforView.current_location : ' '}</Descriptions.Item>
                <Descriptions.Item label="Handover Quantity: ">{dataforView ? dataforView.handover_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Deliverd Quantity: ">{dataforView ? dataforView.delivered_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Returned Quantity: ">{dataforView ? dataforView.returned_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Claims Quantity: ">{dataforView ? dataforView.claims_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Client Name: ">{dataforView ? dataforView.client_name : ' '}</Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Card title="History">
              <ReactTable
                data={history}
                columns={itemListColumns}
              />
            </Card>
          </Container>
        ) : (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the item you visited is not covered by your client."
            // Because eslint auto format line 16
            /* eslint-disable */
            extra={
              <Button type="primary">
                <NavLink to="/items">Back To List</NavLink>
              </Button>
            }
            /* eslint-enable */
          />
        )}
      </div>
    );
  }
}

ItemListView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doItemfetchviews: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doItemfetchviews: itemfetchviews,
};

const WrappedItemListView = Form.create({ name: 'create' })(ItemListView);
export default connect(null, mapDispatchToProps)(WrappedItemListView);
