import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  NavLink,
  Switch,
  // Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Layout, Button, Icon, Drawer, Menu, Popover, BackTop,
} from 'antd';
import sidebarItems from '../navigation/sidebarItems';
import appRoutes from '../navigation/appRoutes';
import RouteComponent from '../components/routes/RouteComponent';
// import MissingComponent from '../components/errors/404';

const {
  Header, Footer, Sider, Content,
} = Layout;

const { SubMenu, Item, ItemGroup } = Menu;

function fetchWindowWidth() {
  const { innerWidth: width } = window;

  return width;
}

function UseWindowWidth() {
  const [windowDimensions, setWindowDimensions] = useState(fetchWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(fetchWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Main = (props) => {
  const width = UseWindowWidth();
  const { user } = props;

  let isLayoutSiderUsable = true;

  if (width <= 768) {
    // Set to false to use drawer component instead of Layout Sider as a sidebar
    isLayoutSiderUsable = false;
  }

  const [isSidebarCollapsed, setDrawerCollapsed] = useState(false);

  const menuItems = [];

  Object.keys(sidebarItems).forEach((root) => {
    const menuSubLayeredItems = [];

    // Uncomment if user roles are set
    //
    // if (!sidebarItems[root].roles.includes(user.role)) {
    //   return null;
    // }

    sidebarItems[root].routes.forEach((content) => {
      // Uncomment if user roles are set
      //
      // if (!content.roles.includes(user.role)) {
      //   return null;
      // }

      if (content.path) {
        menuSubLayeredItems.push(
          <Item key={content.key}>
            <NavLink to={content.path}>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={content.icon} fixedWidth />
              </Icon>
              <span>{content.text}</span>
            </NavLink>
          </Item>,
        );
      } else {
        menuSubLayeredItems.push(
          <SubMenu
            key={content.key}
            title={(
              <span>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={content.icon} fixedWidth />
                </Icon>
                <span>{content.text}</span>
              </span>
          )}
          >

            {/*
              // Uncomment if user roles are set
              //
            { content.items.map(subContent => (subContent.roles.includes(user.role) ? (
              <Item key={item.key}>
                <NavLink
                  to={{ pathname: subContent.path }}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={subContent.icon} fixedWidth />
                  </Icon>
                  <span>{subContent.text}</span>
                </NavLink>
              </Item>
            ) : null))} */}

            {
              content.items.map(subContent => (
                <Item key={subContent.key}>
                  <NavLink to={subContent.path}>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={subContent.icon} fixedWidth />
                    </Icon>
                    <span>{subContent.text}</span>
                  </NavLink>
                </Item>
              ))
            }
          </SubMenu>,
        );
      }
    });

    menuItems.push(
      <ItemGroup
        key={root}
        style={
        {
          textAlign: isSidebarCollapsed && width > 768 ? 'center' : '',
          transition: '0.1s ease-in',
        }
        }
      >
        <ItemGroup key={root} title={root} style={{ fontWeight: 550 }} />
        {menuSubLayeredItems}
      </ItemGroup>,
    );
  });

  return (
    <div>
      {
        isLayoutSiderUsable ? (
          <Layout>
            <Sider
              width={260}
              collapsed={isSidebarCollapsed}
            >
              <div className="app-logo-container" style={{ padding: '16px 40px' }}>
                <img
                  className="app-logo"
                  src="/logo-colored.png"
                  alt="Logo"
                  style={{
                    width: !isSidebarCollapsed ? '100px' : '40px',
                    height: !isSidebarCollapsed ? '40px' : '20px',
                    transition: '0.1s ease-in',
                  }}
                />
              </div>
              <Menu
                mode="inline"
                theme="dark"
              >
                {menuItems}
              </Menu>
            </Sider>
            <Layout>
              <Header>
                <div className="app-header">
                  <div className="app-left-header">
                    <Button type="link" onClick={() => setDrawerCollapsed(!isSidebarCollapsed)}>
                      <Icon
                        className="icon-screen-collapse"
                        type={isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                      />
                    </Button>
                  </div>
                  <div className="app-right-header">
                    <Popover
                      placement="bottomLeft"
                      trigger="click"
                      content={(
                        <React.Fragment>
                          <p>
                            <span style={{ fontWeight: 600 }}>Username: </span>
                            <span>{`${user.username}`}</span>
                          </p>
                          {/* <p>
                            <span style={{ fontWeight: 600 }}>Role: </span>
                            <span>{`${user.role}`}</span>
                          </p>
                          <p>
                            <span style={{ fontWeight: 600 }}>Branch: </span>
                            <span>{`${branch}`}</span>
                          </p> */}
                          <Button type="danger" block>
                            <NavLink to="/logout">Logout</NavLink>
                          </Button>
                        </React.Fragment>
                      )}
                    >
                      <div className="right-header-content">
                        <Icon style={{ marginRight: '8px', fontSize: '15px' }} type="smile" />
                        <span style={{ marginRight: '8px' }}>{`${user.firstname} ${user.lastname}`}</span>
                      </div>
                    </Popover>
                  </div>
                </div>
              </Header>
              <Content>
                <Switch>
                  {appRoutes.map(route => (
                    <RouteComponent
                      key={route.path}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                  {/* <Route path="/404" component={MissingComponent} /> */}
                  <Redirect to="/404" />
                </Switch>
                <BackTop />
              </Content>
              <Footer>
                <p className="ant-footer-title">Ximex Delivery Express Inc.</p>
                <p className="ant-footer-subtitle">
                Developed By: IT Development Team
                  { ' - ' }
                  { process.env.REACT_APP_GIT_SHA ? process.env.REACT_APP_GIT_SHA : ' Development' }
                </p>
              </Footer>
            </Layout>
          </Layout>
        ) : (
          <Layout>
            <Header>
              <div className="app-header">
                <div className="app-left-header" style={{ padding: '5px 20px' }}>
                  <img
                    className="app-logo"
                    src="/logo-colored.png"
                    alt="Logo"
                    style={{
                      width: '50px',
                      height: '30px',
                      transition: '0.1s ease-in',
                    }}
                  />
                  <Button type="link" onClick={() => setDrawerCollapsed(!isSidebarCollapsed)}>
                    <Icon
                      className="icon-screen-collapse"
                      type={!isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                    />
                  </Button>
                </div>
                <div className="app-right-header">
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    content={(
                      <React.Fragment>
                        <p>
                          <span style={{ fontWeight: 600 }}>Username: </span>
                          <span>{`${user.firstname} ${user.lastname}`}</span>
                        </p>
                        {/* <p>
                          <span style={{ fontWeight: 600 }}>Role: </span>
                          <span>admin</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: 600 }}>Branch: </span>
                          <span>Alabang</span>
                        </p> */}
                        <Button type="danger" block>
                          <NavLink to="/logout">Logout</NavLink>
                        </Button>
                      </React.Fragment>
                    )}
                  >
                    <div className="right-header-content">
                      <Icon style={{ marginRight: '8px', fontSize: '15px' }} type="smile" />
                      <span style={{ marginRight: '2px' }}>{`${user.firstname} ${user.lastname}`}</span>
                    </div>
                  </Popover>
                </div>
              </div>
              <Drawer
                title={(
                  <div className="app-drawer-logo">
                    <img
                      className="app-logo"
                      src="/logo-colored.png"
                      alt="Logo"
                      style={{
                        width: '80px',
                        height: '40px',
                        transition: '0.1s ease-in',
                      }}
                    />
                  </div>
                )}
                placement="left"
                visible={isSidebarCollapsed}
                closable={false}
                onClose={() => setDrawerCollapsed(!isSidebarCollapsed)}
                zIndex
              >
                <Menu
                  mode="inline"
                  theme="dark"
                >
                  {menuItems}
                </Menu>
              </Drawer>
            </Header>
            <Content>
              <Switch>
                {appRoutes.map(route => (
                  <RouteComponent
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                ))}
                {/* <Route path="/404" component={MissingComponent} /> */}
                <Redirect to="/404" />
              </Switch>
              <BackTop />
            </Content>
            <Footer>
              <p className="app-footer-title">Ximex Delivery Express Inc.</p>
              <p className="app-footer-subtitle">
                Created By: IT Development Team
                {' - '}
                { process.env.REACT_APP_GIT_SHA ? process.env.REACT_APP_GIT_SHA : 'Development' }
              </p>
            </Footer>
          </Layout>
        )
      }
    </div>
  );
};

Main.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  // doFetchOptions: fetchOptions,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Main),
);
