import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Button, Icon, Spin, Col, Row, notification,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchView from '../../components/DispatchView';
import { fetchMerchantPickupDetails } from './newDucks';
import { printPickup } from '../ducks';
import Spacer from '../../components/Spacer';
import CardImageList from '../../components/CardImageList';

const styleTableHeader = width => ({
  borderCollapse: 'collapse',
  border: '1px solid black',
  fontSize: '12px',
  padding: '5px',
  width,
});

export class MerchantPickupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: [
        {
          packages: [],
          isMountingModule: true,
        },
      ],
    };
  }

  componentDidMount() {
    const { doFetchMerchantPickupView, match } = this.props;

    doFetchMerchantPickupView(match.params.id).then((action) => {
      this.setState({
        dispatchDetails: action.payload.data,
        isMountingModule: false,
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  printDispatch() {
    const { doPrintPickup, match } = this.props;

    doPrintPickup(match.params.id).then(() => {
      notification.success({
        message: 'Success sending message information to shipper.',
        description: 'You can now print your manifest, Thank you.',
        duration: 5,
      });
    });

    const content = document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  render() {
    const { dispatchDetails, isMountingModule } = this.state;
    const { match } = this.props;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Merchant Pickup',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `View #${match.params.id}`,
      },
    ];

    const dispatchPackageListColumns = [
      {
        id: 'tracking_number',
        Header: 'Tracking Number',
        filterable: false,
        sortable: false,
        accessor: data => data.tracking_number,
      },
      {
        id: 'consignee_name',
        Header: 'Consignee Name',
        filterable: false,
        sortable: false,
        accessor: data => data.consignee_name,
      },
      {
        id: 'consignee_address',
        Header: 'Consignee Address',
        filterable: false,
        sortable: false,
        accessor: data => data.consignee_address,
      },
      {
        id: 'payment_type',
        Header: 'Payment Type',
        filterable: false,
        sortable: false,
        accessor: data => data.payment_type,
      },
      {
        id: 'statud',
        Header: 'Status',
        filterable: false,
        sortable: false,
        accessor: data => data.status,
      },
    ];
    return (
      <div className="MerchantPickupView">
        <div style={{ display: 'none' }} id="divcontents">
          <center>
            <strong style={{ fontSize: '14px' }}>PICKUP ROUTE SHEET</strong>
          </center>
          <div style={{ marginBottom: '20px' }}>
            <img
              style={{ float: 'left' }}
              src="http://portal.xdelogistics.com/public/img/xde-logo.png"
              alt="xdeLogo"
            />
            <div
              style={{
                float: 'left',
                paddingTop: '15px',
                marginLeft: '20px',
                width: '570px',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>XDE Logistics</strong>
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                Ximex Building, #9B , Industrial Complex, Bagumbayan, Bicutan, 1st Ave, Taguig, 1631
                Metro Manila
              </p>
            </div>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'left',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Pickup ID: </strong>
                {match.params.id}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Vehicle/Plate Number: </strong>
                {dispatchDetails.pickup ? dispatchDetails.pickup.vehicle_name : ''}
                {'/'}
                {dispatchDetails.pickup ? dispatchDetails.pickup.plate_number : ''}
              </p>
            </div>
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Created Date: </strong>
                {dispatchDetails.pickup ? dispatchDetails.pickup.created_at : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Operator: </strong>
                {dispatchDetails.pickup ? dispatchDetails.pickup.driver : ''}
              </p>
            </div>
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Expected Arrival: </strong>
                {dispatchDetails.pickup ? dispatchDetails.pickup.expected_arrival : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Mobile Number: </strong>
                {dispatchDetails.pickup ? dispatchDetails.pickup.driver_mobile : ''}
              </p>
            </div>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '33%',
              marginBottom: '15px',
            }}
          >
            <p style={{ fontSize: '12px', margin: '5px' }}>
              <strong>Pickup Point: </strong>
              {dispatchDetails.pickup ? dispatchDetails.pickup.pickup_point : ''}
            </p>
            <p style={{ fontSize: '12px', margin: '5px' }}>
              <strong>Remarks: </strong>
              {dispatchDetails.pickup ? dispatchDetails.pickup.remarks : ''}
            </p>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '67%',
              marginBottom: '15px',
              float: 'right',
            }}
          >
            <p style={{ fontSize: '12px', margin: '5px' }}>
              <strong>Pickup Address: </strong>
              {dispatchDetails.pickup ? dispatchDetails.pickup.pickup_address : ''}
            </p>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <table style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={styleTableHeader('20%')}>Request Date</th>
                  <th style={styleTableHeader('40%')}>Order #</th>
                  <th style={styleTableHeader('20%')}>Waybill #</th>
                  <th style={styleTableHeader('10%')}>Signature</th>
                  <th style={styleTableHeader('10%')}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {
                  /* eslint-disable */
                  dispatchDetails.packages
                    ? dispatchDetails.packages.map((val, key) => (
                      <React.Fragment key={key}>
                        <tr key={`${key}a`}>
                          <td style={styleTableHeader('20%')}>{val.created_at}</td>
                          <td style={styleTableHeader('40%')}>{val.package_id}</td>
                          <td style={styleTableHeader('20%')}>{val.tracking_number}</td>
                          <td style={styleTableHeader('10%')} />
                          <td style={styleTableHeader('10%')} />
                        </tr>
                        <tr key={`${key}b`}>
                          <td
                            colSpan={24}
                            style={{
                              borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px',
                            }}
                          >
                            <strong>Item Description: </strong>
                            {val.description}
                          </td>
                        </tr>
                        <tr key={`${key}c`}>
                          <td
                            colSpan={24}
                            style={{
                              borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px',
                            }}
                          >
                            <strong>Consignee Address: </strong>
                            {val.consignee_address}
                          </td>
                        </tr>
                        <tr key={`${key}d`}>
                          <td
                            colSpan={3}
                            style={{
                              borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px',
                            }}
                          >
                            <strong>Length: </strong>
                            {parseFloat(val.length).toFixed(2)}
                            {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                            <strong>Width: </strong>
                            {parseFloat(val.width).toFixed(2)}
                            {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                            <strong>Height: </strong>
                            {parseFloat(val.height).toFixed(2)}
                            {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                            <strong>Weight: </strong>
                            {parseFloat(val.weight).toFixed(2)}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px',
                            }}
                          >
                            <strong>Time: </strong>
                          </td>
                        </tr>
                      </React.Fragment>
                    )) : null
                }
              </tbody>
            </table>
          </div>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total Transaction:'}
            {' '}
            <span style={{ color: 'red' }}>{dispatchDetails.packages ? dispatchDetails.packages.length : 0}</span>
          </p>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total Weight:'}
            {' '}
            {dispatchDetails.packages
              ? dispatchDetails.packages.length !== 0
                ? dispatchDetails.packages
                  .map(res => parseFloat(res.weight))
                  .filter(resFilter => resFilter).length === 0
                  ? 0
                  : dispatchDetails.packages
                    .map(res => parseFloat(res.weight))
                    .filter(resFilter => resFilter)
                    .reduce((a, b) => a + b)
                    .toFixed(2)
                : 0
              : 0}
          </p>
          <div style={{ marginTop: '40px' }}>
            <strong style={{ fontSize: '12px', float: 'left' }}>
              {'Signature of Warehouse Representative'}
            </strong>
            <strong style={{ fontSize: '12px', float: 'left', marginLeft: '250px' }}>
              {'Signature of Operator'}
            </strong>
          </div>
        </div>
        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />
        <Spin spinning={isMountingModule} size="small" tip="Fetching merchant pickup details. . .">
          <PageHeader title={`Merchant Pickup #${match.params.id}`} routes={breadCrumbs} />
          <Spacer />
          <Container>
            <DispatchView
              dispatchTitle="Merchant Pickup Information"
              dispatchItems={dispatchDetails.packages}
              dispatchDetails={(
                <React.Fragment>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="Vehicle: ">{dispatchDetails.pickup ? dispatchDetails.pickup.vehicle_name : ''}</Descriptions.Item>
                    <Descriptions.Item label="Type: ">{dispatchDetails.pickup ? dispatchDetails.pickup.type : ''}</Descriptions.Item>
                    <Descriptions.Item label="Pickup Date: ">{dispatchDetails.pickup ? dispatchDetails.pickup.created_at : ''}</Descriptions.Item>
                    <Descriptions.Item label="Expected Pickup Time: ">{dispatchDetails.pickup ? dispatchDetails.pickup.expected_arrival : ''}</Descriptions.Item>
                    <Descriptions.Item label="Processed by: ">{dispatchDetails.pickup ? dispatchDetails.pickup.created_by : ''}</Descriptions.Item>
                    <Descriptions.Item label="Pickup Point: ">{dispatchDetails.pickup ? dispatchDetails.pickup.pickup_point : ''}</Descriptions.Item>
                    <Descriptions.Item label="Pickup Address: " span={2}>{dispatchDetails.pickup ? dispatchDetails.pickup.pickup_address : ''}</Descriptions.Item>
                    <Descriptions.Item label="Remarks: ">{dispatchDetails.pickup ? dispatchDetails.pickup.remarks : ''}</Descriptions.Item>
                  </Descriptions>
                </React.Fragment>
              )}
              dispatchPackageListColumns={dispatchPackageListColumns}
              printTypes={(
                <React.Fragment>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} lg={12} style={{ marginBottom: 5 }}>
                      <Button onClick={() => this.printDispatch()} type="primary" block disabled>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                          Print Manifest
                      </Button>
                    </Col>
                    <Col xs={24} sm={12} lg={12} style={{ marginBottom: 5 }}>
                      <Button 
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}/v2/internal/pickup/${match.params.id}/final-manifest`
                          )
                        }
                        type="primary" block
                        style={{ paddingRight: 60 }}
                        disabled
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                          Print Final Manifest
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              hasExport
              exportType="pickup_dispatch"
              dispatchExportName="Merchant-Pickup"
              exportId={match.params.id}
            />
          </Container>
          <Spacer />
          
          <CardImageList
            pod={dispatchDetails.pod}
          />
        </Spin>
      </div>
    );
  }
}

MerchantPickupView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchMerchantPickupView: PropTypes.func.isRequired,
  doPrintPickup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchMerchantPickupView: fetchMerchantPickupDetails,
  doPrintPickup: printPickup,
};

export default connect(null, mapDispatchToProps)(MerchantPickupView);
