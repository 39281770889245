import {
  apiFetchPortClusterLocationTypeWhereIn,
  apiFetchPortClusterLocationType,
} from '../services/apiEndpoints';

export const FETCH_PORT_CLUSTER_LOCATION_TYPE = 'portCluster/FETCH_PORT_CLUSTER_LOCATION_TYPE';
export const fetchPcLocationTypeRequest = string => ({
  type: FETCH_PORT_CLUSTER_LOCATION_TYPE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterLocationType(string),
    },
  },
});

export const FETCH_LOCATION_TYPE_WHERE_IN = 'portCluster/FETCH_LOCATION_TYPE_WHERE_IN';
export const fetchLocationTypeLikeRequestWhereIn = (endpoint, params) => ({
  type: FETCH_LOCATION_TYPE_WHERE_IN,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterLocationTypeWhereIn(endpoint),
      params,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
