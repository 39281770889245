import { apiLogin } from '../../services/apiEndpoints';

export const LOGIN = 'login/LOGIN';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'login/LOGIN_FAIL';
export const login = (username, password) => ({
  type: LOGIN,
  payload: {
    client: 'open',
    request: {
      ...apiLogin,
      data: {
        username,
        password,
      },
    },
  },
});

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const logout = () => ({
  type: LOGOUT,
});


export const ACCESS_TOKEN = 'auth/ACCESS_TOKEN';
export const ACCESS_TOKEN_SUCCESS = 'auth/ACCESS_TOKEN_SUCCESS';

const initialState = {
  isLoggingIn: false,
  user: null,
  error: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggingIn: true,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        user: action.payload.data.user,
        error: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggingIn: false,
        error: 'Invalid user credentials',
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
