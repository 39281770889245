import {
  all, put, call, takeLatest
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { store, remove } from '../../services/localStorage';
import { 
  LOGIN_SUCCESS, 
  LOGOUT,
} from './ducks';

function* success(action) {
  const { access_token } = action.payload.data;

  yield call(store, 'access_token', access_token);

  yield put(push('/'));
}

function* logout() {
  yield call(remove, 'token');
  yield put(push('/login'));
}

export default function* () {
  yield all([takeLatest(LOGIN_SUCCESS, success)]);
  yield all([takeLatest(LOGOUT, logout)]);
}
