import {
  apiCreateWaybill,
  waybillList,
  getOptions,
  getOptionsCity,
  getOptionsProvinceCity,
  massUpload,
  apiExportWaybill,
  apiFetchWaybillList,
  apiPrintWaybillList,
  apiExportWaybillItems,
  fetchItemsInWaybill,
  waybillViews,
  apiFetchWaybillTransactionHistory,
  apiCreateUploadTemplate,
  apiValidateWaybillUpload,
  apiUploadWaybill,
  apiFetchWaybillDetails,
} from '../../services/apiEndpoints';

const CREATE_WAYBILL = 'waybill/CREATE_WAYBILL';
export const createWaybill = data => ({
  type: CREATE_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateWaybill,
      data,
    },
  },
});

const FETCH_WAYBILL_DETAILS = 'waybill/FETCH_WAYBILL_DETAILS';
export const fetchWaybillDetails = trackingNumber => ({
  type: FETCH_WAYBILL_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchWaybillDetails(trackingNumber),
    },
  },
});

const FETCH_CLIENT_TRANSACTION_HISTORY = 'dispatch/FETCH_CLIENT_TRANSACTION_HISTORY';
export const fetchWaybillTransactionHistory = trackingNumber => ({
  type: FETCH_CLIENT_TRANSACTION_HISTORY,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchWaybillTransactionHistory(trackingNumber),
    },
  },
});

const EXPORT_WAYBILL = 'waybill/EXPORT_WAYBILL';
export const exportWaybill = (data, progress) => ({
  type: EXPORT_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiExportWaybill,
      ...progress,
      data,
    },
  },
});

const MASS_UPLOAD = 'waybill/MASS_UPLOAD';

export const massUploadData = bodyFormData => ({
  type: MASS_UPLOAD,
  payload: {
    client: 'secure',
    request: {
      ...massUpload,
      data: bodyFormData,
    },
  },
});

const WAYBILL_LIST = 'waybill/WAYBILL_LIST';
const WAYBILL_LIST_SUCCESS = 'waybill/WAYBILL_LIST_SUCCESS';

export const viewWaybillList = data => ({
  type: WAYBILL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...waybillList,
      data,
    },
  },
});

const WAYBILL_LIST_FILTER = 'waybill/WAYBILL_LIST_FILTER';
const WAYBILL_LIST_FILTER_SUCCESS = 'waybill/WAYBILL_LIST_FILTER_SUCCESS';

export const viewWaybillFilterList = data => ({
  type: WAYBILL_LIST_FILTER,
  payload: {
    client: 'secure',
    request: {
      ...waybillList,
      data,
    },
  },
});

const CREATE_WAYBILL_UPLOAD_TEMPLATE = 'waybill/CREATE_WAYBILL_UPLOAD_TEMPLATE';
export const createWaybillUploadTemplate = data => ({
  type: CREATE_WAYBILL_UPLOAD_TEMPLATE,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateUploadTemplate,
      data,
    },
  },
});

const VALIDATE_WAYBILL_UPLOAD = 'waybill/VALIDATE_WAYBILL_UPLOAD';
export const validateWaybillUpload = data => ({
  type: VALIDATE_WAYBILL_UPLOAD,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateWaybillUpload,
      data,
    },
  },
});

const UPLOAD_WAYBILL = 'waybill/UPLOAD_WAYBILL';
export const uploadWaybill = data => ({
  type: UPLOAD_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadWaybill,
      data,
    },
  },
});

const FETCH_OPTIONS_CLIENT = 'waybill/FETCH_OPTIONS_CLIENT';
const FETCH_OPTIONS_CLIENT_SUCCESS = 'waybill/FETCH_OPTIONS_CLIENT_SUCCESS';

const FETCH_OPTIONS_BRANCHCODE = 'waybill/FETCH_OPTIONS_BRANCHCODE';
const FETCH_OPTIONS_BRANCHCODE_SUCCESS = 'waybill/FETCH_OPTIONS_BRANCHCODE_SUCCESS';

const FETCH_OPTIONS_PROVINCE = 'waybill/FETCH_OPTIONS_PROVINCE';
const FETCH_OPTIONS_PROVINCE_SUCCESS = 'waybill/FETCH_OPTIONS_PROVINCE_SUCCESS';

const FETCH_OPTIONS_CITY = 'waybill/FETCH_OPTIONS_CITY';
const FETCH_OPTIONS_CITY_SUCCESS = 'waybill/FETCH_OPTIONS_CITY_SUCCESS';

const SEARCH_OPTION_PROVCITY = 'waybill/FETCH_OPTIONS_CITY';

export const getOptionsProvCity = (type, data) => ({
  type: SEARCH_OPTION_PROVCITY,
  payload: {
    client: 'secure',
    request: {
      ...getOptionsProvinceCity(type, data),
    },
  },
});

export const fetchCity = city => ({
  type: FETCH_OPTIONS_CITY,
  payload: {
    client: 'secure',
    request: {
      ...getOptionsCity(city),
    },
  },
});

export const fetchOptions = (option) => {
  switch (option) {
    case 'client':
      return {
        type: FETCH_OPTIONS_CLIENT,
        payload: {
          client: 'secure',
          request: {
            ...getOptions(option),
          },
        },
      };

    case 'branch_code':
      return {
        type: FETCH_OPTIONS_BRANCHCODE,
        payload: {
          client: 'secure',
          request: {
            ...getOptions(option),
          },
        },
      };

    case 'province':
      return {
        type: FETCH_OPTIONS_PROVINCE,
        payload: {
          client: 'secure',
          request: {
            ...getOptions(option),
          },
        },
      };

    default:
      return false;
  }
};

const FETCH_WAYBILL_LIST = 'waybill/FETCH_WAYBILL_LIST';
export const fetchWaybillList = data => ({
  type: FETCH_WAYBILL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchWaybillList,
      data,
    },
  },
});

const PRINT_WAYBILL_LIST = 'waybill/PRINT_WAYBILL_LIST';
export const printWaybillList = data => ({
  type: PRINT_WAYBILL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiPrintWaybillList,
      data,
    },
  },
});

const EXPORT_WAYBILL_ITEMS = 'waybill/EXPORT_WAYBILL_ITEMS';
export const exportWaybillItems = data => ({
  type: EXPORT_WAYBILL_ITEMS,
  payload: {
    client: 'secure',
    request: {
      ...apiExportWaybillItems,
      data,
    },
  },
});

const WAYBILL_ACCOUNT_VIEW = 'componentWaybill/WAYBILL_ACCOUNT_VIEW';
const WAYBILL_ACCOUNT_VIEW_SUCCESS = 'componentWaybill/WAYBILL_ACCOUNT_VIEW_SUCCESS';

export const waybill = tNumber => ({
  type: WAYBILL_ACCOUNT_VIEW,
  payload: {
    client: 'secure',
    request: {
      ...waybillViews(tNumber),
    },
  },
});

const WAYBILL_ITEM_LIST = 'componentWaybill/WAYBILL_ITEM_LIST';

export const waybillItemList = tNumber => ({
  type: WAYBILL_ITEM_LIST,
  payload: {
    client: 'secure',
    request: {
      ...fetchItemsInWaybill(tNumber),
    },
  },
});

const SAVE_TEMPLATE_TO_STATE = 'waybill/SAVE_TEMPLATE_TO_STATE';
export const saveExportTemplateToState = exportTemplate => ({
  type: SAVE_TEMPLATE_TO_STATE,
  payload: {
    data: {
      exportTemplate,
    },
  },
});

export const defaultTemplate = {
  client: true,
  tracking_number: true,
  package_id: true,
  order_no: false,
  status: true,
  status_reference: true,
  reference_1: true,
  reference_2: false,
  reference_3: false,
  reference_4: false,
  reference_5: false,
  group_id: false,
  payment_type: true,
  total_price: true,
  declared_value: true,
  package_size: false,
  package_total_quantity: true,
  package_length: true,
  package_width: true,
  package_height: true,
  package_weight: true,
  package_type: true,
  package_remarks: false,
  delivery_type: true,
  transport_mode: false,
  shipping_type: true,
  journey_type: false,
  item_id: true,
  item_description: true,
  consignee_name: true,
  consignee_mobile_number: true,
  consignee_email_address: false,
  consignee_full_address: true,
  consignee_province: true,
  consignee_city: true,
  consignee_barangay: true,
  consignee_building_type: false,
  consignee_coordinate: false,
  shipper_id: false,
  shipper_name: false,
  shipper_contact_number: false,
  shipper_address: false,
  shipper_cluster: false,
  shipper_port_code: false,
  shipper_barangay: false,
  shipper_city: false,
  shipper_province: false,
  ageing: true,
  first_attempt_status: false,
  first_attempt_description: false,
  first_attempt_comment: false,
  first_attempt_date: false,
  second_attempt_status: false,
  second_attempt_description: false,
  second_attempt_comment: false,
  second_attempt_date: false,
  third_attempt_status: false,
  third_attempt_description: false,
  third_attempt_comment: false,
  third_attempt_date: false,
  last_status_date: true,
  last_status_reference: false,
  last_status_remarks: false,
  updated_by: false,
  picked_date: false,
  accepted_main_date: false,
  transfer_date: false,
  accepted_branch_reference: false,
  accepted_branch_date: false,
  delivery_date: false,
  last_delivery_date: false,
  handover_date: true,
  number_of_attempts: false,
  location: true,
  target_location: true,
  package_cluster: true,
  received_by: false,
  pickup_datetime: false,
  pickup_start_time: false,
  pickup_end_time: false,
  created_at: true,
  updated_at: false,
};


const initialState = {
  views: {},
  resCreate: {},
  listView: {},
  options: {
    client: [],
    branchCode: [],
    province: [],
    city: [],
  },
  exportTemplate: defaultTemplate,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case WAYBILL_ACCOUNT_VIEW_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    case WAYBILL_LIST_SUCCESS:
      return {
        ...state,
        listView: action.payload.data,
      };

    case WAYBILL_LIST_FILTER_SUCCESS:
      return {
        ...state,
        listView: action.payload.data,
      };

    case FETCH_OPTIONS_CLIENT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          client: action.payload.data,
        },
      };

    case FETCH_OPTIONS_BRANCHCODE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          branchCode: action.payload.data,
        },
      };

    case FETCH_OPTIONS_PROVINCE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          province: action.payload.data,
        },
      };

    case FETCH_OPTIONS_CITY_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          city: action.payload.data,
        },
      };
    case SAVE_TEMPLATE_TO_STATE:
      return {
        ...state,
        exportTemplate: action.payload.data.exportTemplate,
      };
    default:
      return state;
  }
}

export default reducer;
