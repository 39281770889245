import {
  apiFetchGroupTrackingNumber,
  apiValidateDimweightFile,
  apiUpdateDimweightDimensions,
  apiLogin,
} from '../../services/apiEndpoints';


const FETCH_GROUP_TRACKING_NUMBER = 'tools/FETCH_GROUP_TRACKING_NUMBER';
export const fetchGroupTrackingNumber = trackingNumber => ({
  type: FETCH_GROUP_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchGroupTrackingNumber(trackingNumber),
    },
  },
});

const VALIDATE_DIMWEIGHT_FILE = 'tools/VALIDATE_DIMWEIGHT_FILE';
export const validateDimweightFile = file => ({
  type: VALIDATE_DIMWEIGHT_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateDimweightFile(),
      data: file,
    },
  },
});

const UPDATE_DIMEWEIGHT_DIMENSIONS = 'tools/UPDATE_DIMEWEIGHT_DIMENSIONS';
export const updateDimweightDimensions = data => ({
  type: UPDATE_DIMEWEIGHT_DIMENSIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateDimweightDimensions(),
      data,
    },
  },
});

export const auth = (username, password) => ({
  type: 'AUTH_UPDATER',
  payload: {
    request: {
      ...apiLogin,
      data: {
        username,
        password,
      },
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
