import React from 'react';
import { NavLink } from 'react-router-dom';
import { Result, Button } from 'antd';

export default () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    // Because eslint auto format line 16
    /* eslint-disable */
    extra={
      <Button type="primary">
        <NavLink to="/">Back Home</NavLink>
      </Button>
    }
    /* eslint-enable */
  />
);
