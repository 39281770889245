import React from 'react';
import { PageHeader as AntPageHeader } from 'antd';
import PropTypes from 'prop-types';

const PageHeader = ({ title, routes, extra }) => (
  <AntPageHeader
    title={title}
    style={{
      padding: '16px 24px 12px',
      backgroundColor: '#fff',
      fontSize: '16px',
    }}
    breadcrumb={{ routes }}
    extra={extra}
  />
);

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  routes: PropTypes.oneOfType([PropTypes.array]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default PageHeader;
