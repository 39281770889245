
export const apiLogin = {
  url: '/v2/login',
  method: 'POST',
};

export const apiRefresh = {
  url: '/v2/refresh',
  method: 'POST',
};

export const getOptions = option => ({
  url: `/web/options/${option}`,
  method: 'GET',
});

export const getOptionsCity = option => ({
  url: `/web/options/city?filter=&reference=${option}`,
  method: 'GET',
});

export const getOptionsProvinceCity = (option, filter) => ({
  url: `/web/options/${option}?filter=${filter}`,
  method: 'GET',
});

export const apiFetchWaybillDetails = trackingNumber => ({
  url: `/web/package/details/${trackingNumber}`,
  method: 'GET',
});

export const apiCreateWaybill = {
  url: '/web/package',
  method: 'POST',
};

export const massUpload = page => ({
  url: `web/${page}/import`,
  method: 'POST',
});

export const apiFetchGroupTrackingNumber = trackingNumber => ({
  url: `/v2/package/${trackingNumber}/group`,
  method: 'GET',
});

export const apiValidateDimweightFile = () => ({
  url: '/v2/package/dimweight/validate',
  method: 'POST',
});

export const apiUpdateDimweightDimensions = () => ({
  url: '/v2/package/dimweight/update',
  method: 'PUT',
});
export const exportwaybill = todo => ({
  url: `/web/package/${todo}`,
  method: 'POST',
});

export const waybillViews = tNumber => ({
  url: `/web/package/details/${tNumber}?history=true`,
  method: 'GET',
});

export const waybillList = {
  url: '/web/package/list',
  method: 'POST',
};

export const apiExportWaybill = {
  url: '/v2/package/export',
  method: 'POST',
  responseType: 'arraybuffer',
};

export const apiCreateUploadTemplate = {
  url: '/v2/package/template',
  method: 'POST',
  responseType: 'arraybuffer',
};

export const apiValidateWaybillUpload = {
  url: '/v2/package/validate/upload',
  method: 'POST',
};

export const apiUploadWaybill = {
  url: '/v2/package/upload',
  method: 'POST',
};

export const fetchItemsInWaybill = tnumber => ({
  url: `/v2/package/${tnumber}/items`,
  method: 'GET',
});

export const fetchItemsViews = (reference, itemId) => ({
  url: `/v2/items/${reference}/${itemId}`,
  method: 'GET',
});

export const apiFetchWaybillTransactionHistory = trackingNumber => ({
  url: `/v2/client/common/services/shipment/${trackingNumber}/history`,
  method: 'GET',
});

export const fetchItemsViewsByReference = reference => ({
  url: `/v2/items/${reference}`,
  method: 'GET',
});

export const fetchItemsAPI = {
  url: '/v2/items',
  method: 'POST',
};

export const itemTrackerTaggingAPI = {
  url: '/v2/items/status',
  method: 'PUT',
};

export const itemTrackerByReferenceAPI = (referenceNumber, status) => ({
  url: `/v2/package/validate/item/${referenceNumber}?status=${status}`,
  method: 'GET',
});

export const itemTrackerByTrackinNumberAPI = (trackingNumber, status) => ({
  url: `/v2/package/validate/package/${trackingNumber}?status=${status}`,
  method: 'GET',
});

export const apiFetchOptionsLike = (type, string) => ({
  url: `/web/options/${type}?filter=${string}`,
  method: 'GET',
});

export const apiFetchOptions = type => ({
  url: `/web/options/${type}`,
  method: 'GET',
});

export const apiFetchWaybillList = {
  url: '/web/package/list',
  method: 'POST',
};

export const apiPrintWaybillList = {
  url: '/web/package/generate-waybills',
  responseType: 'arraybuffer',
  method: 'POST',
};

export const apiExportWaybillItems = {
  url: '/web/package/items',
  method: 'POST',
};

export const apiFetchClientDetails = id => ({
  url: `/web/client/${id}`,
  method: 'GET',
});

export const apiUpdateTrackingNumberStatus = trackingNumber => ({
  url: `/web/package/status/${trackingNumber}`,
  method: 'POST',
});

export const apiFetchTrackingNumberByStatus = (status, trackingNumber) => ({
  url: `/web/package/validate/${status}/${trackingNumber}`,
  method: 'GET',
});

// - Shipper endpoints -

export const apiFetchShipperDetails = id => ({
  url: `/v2/shipper/${id}`,
  method: 'GET',
});

export const apiFetchShipperOptionsLike = string => ({
  url: `/v2/shipper/options/like/${string}`,
  method: 'GET',
});

export const apiFetchShipperOptions = {
  url: '/v2/shipper/options',
  method: 'GET',
};

// - Shipper endpoints -

// - Port Cluster endpoints -

// - Merchant endpoints

export const apiSearchMerchant = filter => ({
  url: `/v2/portal/merchant?filter=${filter}`,
  method: 'GET',
});

export const apiGetPickupDetails = (shipperID, filter) => ({
  url: `/v2/portal/pickupDetails/${shipperID}?filter=${filter}`,
  method: 'POST',
});

export const apiGetManifestList = {
  url: '/v2/portal/manifestList',
  method: 'POST',
};

export const printPickupManifest = printID => ({
  url: `/web/pickup/print/${printID}`,
  method: 'GET',
});

export const apiDeleteDispatch = dispatchId => ({
  url: `/web/dispatch/${dispatchId}`,
  method: 'DELETE',
});

export const apiFetchTransmittalList = {
  url: '/web/transmittal/list/get',
  method: 'POST',
};

export const apiFetchPickupList = {
  url: '/web/pickup/list/get',
  method: 'POST',
};

export const apiFetchDispatchList = {
  url: '/web/dispatch/list/get',
  method: 'POST',
};

export const apiFetchMultiMerchantPickupTrackingNumber = trackingNumber => ({
  url: `/web/pickup/package/multi-merchant/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchMerchantPickupDetails = pickupId => ({
  url: `/web/pickup/${pickupId}`,
  method: 'GET',
});

export const apiUpdateMerchantPickup = pickupId => ({
  url: `/web/pickup/${pickupId}`,
  method: 'POST',
});

export const apiCreateMerchantPickup = {
  url: '/web/pickup',
  method: 'POST',
};

export const apiFetchMerchantPickupTrackingNumber = trackingNumber => ({
  url: `/web/pickup/package/merchant/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchPortClusterLocationType = type => ({
  url: `/v2/port-cluster/${type}`,
  method: 'GET',
});

export const apiFetchPortClusterLocationTypeWhereIn = type => ({
  url: `/v2/port-cluster/${type}/where`,
  method: 'GET',
});

// - Port Cluster endpoints

// - Tools endpoints -
