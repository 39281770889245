import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from './Card';
import Spacer from './Spacer';
import ReactTable from './ReactTable';

export class DispatchView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      dispatchDetails,
      dispatchItems,
      dispatchTitle,
      dispatchPackageListColumns,
      printTypes,
      loadingUpdate,
    } = this.props;
    return (
      <div className="DispatchView">
        <Card title={dispatchTitle} extra={printTypes}>
          {dispatchDetails}
        </Card>
        <Spacer />
        <Card
          title="Package List"
          extra={`Total: ${
            dispatchItems !== undefined ? dispatchItems.length : 0
          }`}
        >
          <ReactTable
            data={dispatchItems}
            columns={dispatchPackageListColumns}
            loading={loadingUpdate}
            loadingText="Updating status. . ."
          />
        </Card>
      </div>
    );
  }
}

DispatchView.propTypes = {
  dispatchItems: PropTypes.oneOfType([PropTypes.array]),
  dispatchDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
    .isRequired,
  dispatchPackageListColumns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dispatchTitle: PropTypes.string.isRequired,
  printTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  loadingUpdate: PropTypes.bool,
};

export default DispatchView;
