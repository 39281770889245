import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Row, Form, Input, Icon, Button,
} from 'antd';
import { connect } from 'react-redux';
import { login } from './ducks';
import { isLoggingIn, error } from './selector';

function fetchWindowWidth() {
  const { innerWidth: width } = window;

  return width;
}

function UseWindowWidth() {
  const [windowDimensions, setWindowDimensions] = useState(fetchWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(fetchWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Login(props) {
  const width = UseWindowWidth();
  const {
    form, doLogin, isSubmitting, hasError,
  } = props;
  const { getFieldDecorator, validateFields } = form;

  let errorMessage = false;

  if (hasError) {
    errorMessage = (
      <span style={{ color: 'red', textAlign: 'center' }}>{hasError}</span>
    );
  }

  const loginHandler = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-unused-vars
    validateFields((err, values) => {
      if (!err) {
        doLogin(values.username, values.password);
      }
    });
  };

  return (
    <div className="auth-container">

      <div className="auth-form-container">
        <Row type="flex" justify="space-around" align="middle">
          {
            width <= 425 ? (
              <div className="auth-container-small">
                <div className="auth-logo-container">
                  <img
                    className="app-logo"
                    src="/logo-colored.png"
                    alt="Logo"
                  />
                </div>
                <h3>
                  Log in to Rubix Client Portal
                </h3>
                <Form>
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: 'Username cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Username"
                        suffix={<Icon type="user" />}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Password cannot be blank.',
                        },
                      ],
                    })(
                      <Input.Password
                        placeholder="Password"
                        type="password"
                      />,
                    )}

                  </Form.Item>
                  {errorMessage || null}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={(e) => { loginHandler(e); }}
                      block
                    >
                    Log in
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <Card style={{ padding: '24px 24px 0 24px' }}>
                <div className="auth-logo-container">
                  <img
                    className="app-logo"
                    src="/logo-colored.png"
                    alt="Logo"
                  />
                </div>
                <h3>
                  Log in to Rubix Client Portal
                </h3>
                {errorMessage || null}
                <Form>
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: 'Username cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Username"
                        suffix={<Icon type="user" />}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Password cannot be blank.',
                        },
                      ],
                    })(
                      <Input.Password
                        placeholder="Password"
                        type="password"
                      />,
                    )}

                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={(e) => { loginHandler(e); }}
                      block
                    >
                    Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )
          }
        </Row>
        <Row>
          <div className="auth-footer">
            <p>Client interface for the Rubix system</p>
            <p>
              Version:
              {' '}
              { process.env.REACT_APP_GIT_SHA }
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
}


Login.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  doLogin: PropTypes.func.isRequired,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

const WrappedLogin = Form.create({ name: 'LoginForm ' })(Login);

const mapStateToProps = state => ({
  isSubmitting: isLoggingIn(state),
  hasError: error(state),
});

const mapDispatchToProps = {
  doLogin: login,
};


export default connect(mapStateToProps, mapDispatchToProps)(WrappedLogin);
