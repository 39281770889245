import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Col,
  DatePicker,
  Icon,
  Statistic,
  Slider,
} from 'antd';
import Row from './Row';
import Card from './Card';
import Spacer from './Spacer';
import ReactTable from './ReactTable';
import Select from './Select';

class StatusTaggingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizeState: 50,
      saveTrackingNumbers: null,
    };

    this.validateTrackingNumber = this.validateTrackingNumber.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  validateTrackingNumber(e) {
    e.preventDefault();
    const { form, validateHandler } = this.props;

    const { validateFields } = form;

    // eslint-disable-next-line no-unused-vars
    validateFields(
      [
        'reason',
        'trackingNumber',
        'comment',
        'timestamp',
        'received_by',
        'reference',
      ],
      (err, values) => {
        if (!err) {
          validateHandler(values);
          form.resetFields(['trackingNumber']);

          this.setState({ saveTrackingNumbers: values.trackingNumber });
        }
      },
    );
  }

  formSubmit(e) {
    e.preventDefault();

    const { form, submitHandler } = this.props;

    const { validateFieldsAndScroll } = form;

    // eslint-disable-next-line no-unused-vars
    validateFieldsAndScroll(
      ['comment', 'dateFrom', 'dateTo', 'mode'],
      (err, values) => {
        if (!err) {
          submitHandler(values);
          form.resetFields();
        }
      },
    );
  }

  render() {
    const {
      form,
      hasDatePicker,
      cardExtra,
      hasReceiver,
      isFailedStatus,
      isFailedStatusRefusal,
      hasReference,
      hasTransmittalMode,
      options,
      trackingNumberList,
      trackingNumberListColumns,
      submitHandlerRefusal,
      errorList,
      successList,
      inputDisabled,
      saveTrackingNumber,
      errorMessageDispatch,
    } = this.props;
    const { getFieldDecorator } = form;
    const { sizeState, saveTrackingNumbers } = this.state;

    return (
      <div className="StatusTaggingForm">
        <Card title="Tracking Number Form" extra={cardExtra}>
          <Form onSubmit={this.validateTrackingNumber}>
            {hasTransmittalMode ? (
              <Row>
                <Col xs={24} sm={24} lg={8}>
                  <Form.Item label="Date From">
                    {getFieldDecorator('dateFrom', {
                      rules: [
                        {
                          required: true,
                          message: 'Date From cannot be blank.',
                        },
                      ],
                    })(
                      <DatePicker
                        placeholder="Select date from"
                        style={{ width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [moment('00:00', 'HH:mm')],
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <Form.Item label="Date To">
                    {getFieldDecorator('dateTo', {
                      rules: [
                        { required: true, message: 'Date To cannot be blank.' },
                      ],
                    })(
                      <DatePicker
                        placeholder="Select date to"
                        style={{ width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [moment('00:00', 'HH:mm')],
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <Form.Item label="Mode">
                    {getFieldDecorator('mode', {
                      rules: [
                        { required: true, message: 'Mode cannot be blank.' },
                      ],
                    })(<Input placeholder="Enter mode" />)}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={24} sm={12} lg={hasDatePicker ? 12 : 24}>
                <Form.Item label={hasTransmittalMode ? 'Remarks' : 'Comment'}>
                  {getFieldDecorator('comment', {
                    rules: [
                      { required: false, message: 'Comment cannot be blank.' },
                    ],
                  })(
                    <Input
                      name="comment"
                      placeholder={
                        hasTransmittalMode ? 'Enter remarks' : 'Enter comment'
                      }
                      autoComplete="off"
                    />,
                  )}
                </Form.Item>
              </Col>
              {hasDatePicker ? (
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Timestamp">
                    {getFieldDecorator('timestamp', {
                      rules: [
                        {
                          required: false,
                          message: 'Timepicker cannot be blank.',
                        },
                      ],
                    })(
                      <DatePicker
                        placeholder="Select timestamp"
                        style={{ width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [moment('00:00', 'HH:mm')],
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <Row>
              {hasReceiver ? (
                <Col>
                  <Form.Item label="Received By">
                    {getFieldDecorator('received_by', {
                      rules: [
                        {
                          required: true,
                          message: 'Received By cannot be blank.',
                        },
                      ],
                    })(<Input placeholder="Enter received by" />)}
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>
            {isFailedStatus ? (
              <Row>
                <Col>
                  <Form.Item label="Reason">
                    {getFieldDecorator('reason', {
                      rules: [
                        { required: true, message: 'Reason cannot be blank.' },
                      ],
                    })(
                      <Select
                        options={options}
                        name="reason"
                        placeholder="Select reason"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            {isFailedStatusRefusal ? (
              <Row>
                <Col>
                  <Form.Item label="Reason">
                    {getFieldDecorator('reason', {
                      rules: [
                        { required: true, message: 'Reason cannot be blank.' },
                      ],
                    })(
                      <Select
                        options={options}
                        name="reason"
                        placeholder="Select reason"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            {hasReference ? (
              <Row>
                <Col>
                  <Form.Item label="Reference">
                    {getFieldDecorator('reference', {
                      rules: [
                        {
                          required: true,
                          message: 'Reference cannot be blank.',
                        },
                      ],
                    })(<Input placeholder="Enter refernce" />)}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col>
                <Form.Item label="Tracking Number">
                  {getFieldDecorator('trackingNumber', {
                    rules: [
                      {
                        required: true,
                        message: 'Tracking Number cannot be blank.',
                      },
                    ],
                  })(
                    <Input.TextArea
                      name="trackingNumber"
                      onPressEnter={this.validateTrackingNumber}
                      placeholder="Enter tracking number(s)"
                      disabled={inputDisabled}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={inputDisabled}
                  block
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                  Add Tracking Number
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {saveTrackingNumber ? (
          saveTrackingNumbers ? (
            <React.Fragment>
              {/* {console.log(errorMessageDispatch)} */}
              <Spacer />
              <Card>
                <Slider
                  defaultValue={50}
                  onChange={e => this.setState({ sizeState: e })}
                />
                <Row>
                  <Col
                    style={{
                      backgroundColor:
                        errorMessageDispatch !== ' ' ? 'red' : null,
                    }}
                    xs={24}
                    sm={24}
                    lg={sizeState > 30 ? 24 : 8}
                  >
                    <Statistic
                      style={{ textAlign: 'center' }}
                      title="Last Tracking Number :"
                      value={`${saveTrackingNumbers}`}
                      valueStyle={{
                        fontSize: `${sizeState}0%`,
                        textAlign: 'center',
                        color: errorMessageDispatch !== ' ' ? 'white' : 'black',
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} lg={sizeState > 30 ? 24 : 8}>
                    <Row>
                      <Col xs={24} sm={24} lg={12}>
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Accepted to Warehouse Success :"
                          value={trackingNumberList.length}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: 'green',
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Dispatch Item Error :"
                          value={errorList.length}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: 'red',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} lg={sizeState > 30 ? 24 : 8}>
                    <Statistic
                      style={{ textAlign: 'center' }}
                      title="Error Message :"
                      value={errorMessageDispatch}
                      valueStyle={{
                        fontSize: `${(sizeState / 2.5) * 10}%`,
                        textAlign: 'center',
                        color: 'red',
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </React.Fragment>
          ) : null
        ) : null}
        <Spacer />
        <Card
          title="Tracking Number List"
          extra={`Total: ${trackingNumberList.length}`}
        >
          <ReactTable
            data={trackingNumberList}
            columns={trackingNumberListColumns}
          />
        </Card>
        {successList.length > 0 ? (
          <React.Fragment>
            <Spacer />
            <Card title="Success List" extra={`Total : ${successList.length}`}>
              <ReactTable
                data={successList}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'City',
                    accessor: 'address_city',
                  },
                  {
                    Header: 'Reference Code',
                    accessor: 'status_reference',
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                ]}
              />
            </Card>
          </React.Fragment>
        ) : null}
        {errorList.length > 0 ? (
          <React.Fragment>
            <Spacer />
            <Card title="Error List" extra={`Total : ${errorList.length}`}>
              <ReactTable
                data={errorList}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Message',
                    accessor: 'message',
                  },
                ]}
              />
            </Card>
          </React.Fragment>
        ) : null}
        <Spacer />
        {isFailedStatusRefusal ? (
          <Button
            htmlType="submit"
            type="primary"
            onClick={e => submitHandlerRefusal(e)}
            disabled={trackingNumberList.length === 0}
            block
          >
            <Icon viewBox="0 0 1024 1024">
              <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
            </Icon>
            Submit
          </Button>
        ) : (
          <Button
            htmlType="submit"
            type="primary"
            onClick={this.formSubmit}
            disabled={trackingNumberList.length === 0}
            block
          >
            <Icon viewBox="0 0 1024 1024">
              <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
            </Icon>
            Submit
          </Button>
        )}
      </div>
    );
  }
}

StatusTaggingForm.defaultProps = {
  cardExtra: null,
  isFailedStatus: false,
  isFailedStatusRefusal: false,
  hasTransmittalMode: false,
  options: [],
  successList: [],
  hasReceiver: false,
  hasDatePicker: false,
  hasReference: false,
};

StatusTaggingForm.propTypes = {
  cardExtra: PropTypes.oneOfType([PropTypes.node]),
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  trackingNumberList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  trackingNumberListColumns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  errorList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  successList: PropTypes.oneOfType([PropTypes.array]),
  hasDatePicker: PropTypes.bool,
  isFailedStatus: PropTypes.bool,
  isFailedStatusRefusal: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array]),
  validateHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func,
  submitHandlerRefusal: PropTypes.func,
  hasTransmittalMode: PropTypes.bool,
  saveTrackingNumber: PropTypes.bool,
  errorMessageDispatch: PropTypes.string,
  hasReceiver: PropTypes.bool,
  hasReference: PropTypes.bool,
  inputDisabled: PropTypes.bool,
};

const WrappedStatusTaggingForm = Form.create({ name: 'StatusTaggingForm' })(
  StatusTaggingForm,
);

export default connect(null, null)(WrappedStatusTaggingForm);
