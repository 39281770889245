import {
  apiFetchOptionsLike,
  apiFetchOptions,
  apiFetchClientDetails,
  apiUpdateTrackingNumberStatus,
  apiFetchTrackingNumberByStatus,
  apiDeleteDispatch,
  apiFetchTransmittalList,
  apiFetchPickupList,
  apiFetchDispatchList,
  printPickupManifest,
} from '../services/apiEndpoints';


const FETCH_OPTIONS = 'dispatch/FETCH_OPTIONS';
export const fetchOptions = option => ({
  type: FETCH_OPTIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptions(option),
    },
  },
});

const FETCH_OPTIONS_LIKE = 'dispatch/FETCH_OPTIONS_LIKE';
export const fetchOptionsLike = (type, string) => ({
  type: FETCH_OPTIONS_LIKE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptionsLike(type, string),
    },
  },
});

const FETCH_CLIENT_DETAILS = 'client/FETCH_CLIENT_DETAILS';
export const fetchClientDetails = id => ({
  type: FETCH_CLIENT_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchClientDetails(id),
    },
  },
});

const UPDATE_TRACKING_NUMBER_STATUS = 'statusTagging/UPDATE_TRACKING_NUMBER_STATUS';
export const updateTrackingNumberStatus = (data, trackingNumber) => ({
  type: UPDATE_TRACKING_NUMBER_STATUS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateTrackingNumberStatus(trackingNumber),
      data,
    },
  },
});

const DELETE_DISPATCH = 'statusTagging/DELETE_DISPATCH';
export const deleteDispatch = dispatchId => ({
  type: DELETE_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteDispatch(dispatchId),
    },
  },
});

const FETCH_TRANSMITTAL_LIST = 'merchantPickup/FETCH_TRANSMITTAL_LIST';
export const fetchTransmittalList = data => ({
  type: FETCH_TRANSMITTAL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTransmittalList,
      data,
    },
  },
});

const FETCH_DISPATCH_LIST = 'dispatch/FETCH_DISPATCH_LIST';
export const fetchDispatchList = data => ({
  type: FETCH_DISPATCH_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchDispatchList,
      data,
    },
  },
});

const PRINT_PICKUP = 'statusTagging/PRINT_PICKUP';
export const printPickup = printPickupManifestId => ({
  type: PRINT_PICKUP,
  payload: {
    client: 'secure',
    request: {
      ...printPickupManifest(printPickupManifestId),
    },
  },
});

const FETCH_PICKUP_LIST = 'pickup/FETCH_PICKUP_LIST';
export const fetchPickupList = data => ({
  type: FETCH_PICKUP_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPickupList,
      data,
    },
  },
});

const FETCH_TRACKING_NUMBER = 'statusTagging/FETCH_TRACKING_NUMBER';
export const fetchTrackingNumber = (status, trackingNumber) => ({
  type: FETCH_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTrackingNumberByStatus(status, trackingNumber),
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
