import React, { Component } from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import {
  Icon, Button, Col, Descriptions, Tag, Spin, Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint, faEye } from '@fortawesome/free-solid-svg-icons';

import { waybill, waybillItemList, fetchWaybillTransactionHistory } from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export class Waybill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      loadSpin: false,
      dataforView: null,
      loadSpinItem: true,
      fetchingPackageStatus: true,
      tnumberItems: [],
      transactionHistory: [],
    };
  }

  componentDidMount() {
    this.setState({ loadSpin: true });
    const {
      waybillComponent, match, history, doWaybillItemList,
      doFetchWaybillTransactionHistory,
    } = this.props;
    waybillComponent(match.params.tnumber).then((res) => {
      if (res.payload.data.package === null) {
        history.push('/404');
      }

      this.setState({ loadSpin: false });
    });

    doWaybillItemList(match.params.tnumber).then((res) => {
      this.setState({
        loadSpinItem: false,
        tnumberItems: res.payload.data,
      });
    });

    doFetchWaybillTransactionHistory(match.params.tnumber).then((res2) => {
      this.setState({
        transactionHistory: res2.payload.data.history,
        fetchingPackageStatus: false,
      });
    });
  }

  openViewModal(rows) {
    this.setState({
      isVisible: true,
      dataforView: rows,
    });
  }

  closeViewModal() {
    this.setState({
      isVisible: false,
      dataforView: null,
    });
  }

  printWaybill() {
    const { viewAccount } = this.props;
    window.open(
      `${process.env.REACT_APP_API_URL}/web/print-waybill/${viewAccount.key}`,
    );
  }

  printFullWaybill() {
    const { viewAccount } = this.props;
    window.open(
      `${process.env.REACT_APP_API_URL}/web/print-waybill/full/${viewAccount.key}`,
    );
  }

  printMotherWaybill() {
    const { viewAccount } = this.props;
    window.open(
      `${process.env.REACT_APP_API_URL}/web/print-waybill/mother/${viewAccount.key}`,
    );
  }

  render() {
    const imageHandler = 'https://img.icons8.com/wired/2x/file-delete.png';
    const {
      loadSpin, isVisible, dataforView, tnumberItems, loadSpinItem,
      transactionHistory, fetchingPackageStatus,
    } = this.state;
    const { viewAccount, history } = this.props;
    const breadCrumbs = [
      {
        breadcrumbName: 'Waybill',
      },
      {
        breadcrumbName: `View #${
          viewAccount.package ? viewAccount.package.tracking_number : ''
        }`,
      },
    ];

    return (
      <div className="ListView">
        <Modal
          title="View Items Information"
          closable
          onCancel={() => this.closeViewModal()}
          visible={isVisible}
          footer={null}
          width={950}
        >
          <Descriptions bordered size="small">
            <Descriptions.Item label="Tracking Number: "><Tag color="orange">{viewAccount.package ? viewAccount.package.tracking_number : ''}</Tag></Descriptions.Item>
            <Descriptions.Item label="Item ID: ">{dataforView ? dataforView.item_id : ' '}</Descriptions.Item>
            <Descriptions.Item label="Reference: ">{dataforView ? dataforView.reference : ' '}</Descriptions.Item>
            <Descriptions.Item label="Description: ">{dataforView ? dataforView.description : ' '}</Descriptions.Item>
            <Descriptions.Item label="Quantity: ">{dataforView ? dataforView.quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="UOM: ">{dataforView ? dataforView.uom : ' '}</Descriptions.Item>
            <Descriptions.Item label="Length: ">{dataforView ? dataforView.length : ' '}</Descriptions.Item>
            <Descriptions.Item label="Width: ">{dataforView ? dataforView.width : ' '}</Descriptions.Item>
            <Descriptions.Item label="Height: ">{dataforView ? dataforView.height : ' '}</Descriptions.Item>
            <Descriptions.Item label="Weight: ">{dataforView ? dataforView.weight : ' '}</Descriptions.Item>
            <Descriptions.Item label="Volume: ">{dataforView ? dataforView.volume : ' '}</Descriptions.Item>
            <Descriptions.Item label="Value: ">{dataforView ? dataforView.value : ' '}</Descriptions.Item>
            <Descriptions.Item label="Comment: ">{dataforView ? dataforView.comment : ' '}</Descriptions.Item>
            <Descriptions.Item label="Status: "><Tag color="orange">{dataforView ? dataforView.status : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Current Location: ">{dataforView ? dataforView.current_location : ' '}</Descriptions.Item>
            <Descriptions.Item label="Handover Quantity: ">{dataforView ? dataforView.handover_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Deliverd Quantity: ">{dataforView ? dataforView.delivered_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Returned Quantity: ">{dataforView ? dataforView.returned_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Claims Quantity: ">{dataforView ? dataforView.claims_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Client Name: ">{dataforView ? dataforView.client_name : ' '}</Descriptions.Item>
          </Descriptions>
        </Modal>
        <PageHeader title="View Waybill" routes={breadCrumbs} />
        <Container>
          <Spin spinning={loadSpin} tip="Loading package details...">
            <Row>
              <Col xs={24} sm={24} lg={8} style={{ marginBottom: 5 }}>
                <div style={{ padding: '5px' }}>
                  <Button
                    type="primary"
                    onClick={e => this.printWaybill(e)}
                    block
                  >
                    <Icon type="printer" />
                    Print Waybill
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} lg={8} style={{ marginBottom: 5 }}>
                <div style={{ padding: '5px' }}>
                  <Button
                    type="primary"
                    onClick={e => this.printFullWaybill(e)}
                    block
                  >
                    <Icon type="printer" />
                    Print Full Waybill
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} lg={8} style={{ marginBottom: 5 }}>
                <div style={{ padding: '5px' }}>
                  <Button
                    type="primary"
                    disabled
                    onClick={e => this.printMotherWaybill(e)}
                    block
                  >
                    <Icon type="printer" />
                    Print Mother Waybill
                  </Button>
                </div>
              </Col>
            </Row>
            <Card>
              <Row>
                <Col xs={24} sm={24} lg={12}>
                  <div style={{ background: '', padding: '5px' }}>
                    <Descriptions
                      title="Package Reference"
                      bordered
                      size="small"
                    >
                      <Descriptions.Item label="Tracking Number" span={3}>
                        <strong>
                          {viewAccount.package
                            ? viewAccount.package.tracking_number
                            : ''}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item label="Status" span={3}>
                        <Tag color="orange">
                          {viewAccount.package
                            ? viewAccount.package.status
                            : ''}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item label="Client" span={3}>
                        {viewAccount.package ? viewAccount.package.client : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Branch Code" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.branch_code
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Package ID" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.package_id
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Group ID" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.group_id
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Order Number" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.order_no
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Reference 1" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.reference_1
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Reference 2" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.reference_2
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Reference 3" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.reference_3
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Reference 4" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.reference_4
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Reference 5" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.reference_5
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Transport Mode" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.transport_mode
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Delivery Type" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.delivery_type
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Shipping Type" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipping_type
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Journey Type" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.journey_type
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Package Type" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.package_type
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Remarks" span={3}>
                        {viewAccount.package ? viewAccount.package.remarks : ''}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <div style={{ background: '', padding: '5px' }}>
                    <Descriptions
                      title="Consignee Details"
                      bordered
                      size="small"
                    >
                      <Descriptions.Item label="Consignee Name" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.consignee_name
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.consignee_address
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Consignee Email" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.consignee_email
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Consignee Mobile" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.consignee_mobile
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="City" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.address_city
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Province" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.address_province
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Barangay" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.address_barangay
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Building Type" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.building_type
                          : ''}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div style={{ background: '', padding: '5px' }}>
                    <Descriptions
                      title="Merchant Information"
                      bordered
                      size="small"
                    >
                      <Descriptions.Item label="Merchant Name" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_name
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_address
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Merchant Email" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_email
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Merchant Mobile" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_contact
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="City" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_city
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Province" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_province
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Barangay" span={3}>
                        {viewAccount.package
                          ? viewAccount.package.shipper_barangay
                          : ''}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div
                    style={{ background: '', padding: '5px', marginTop: 10 }}
                  >
                    <Descriptions
                      title="Package Information"
                      bordered
                      size="small"
                    >
                      <Descriptions.Item label="Package Size" span={1.5}>
                        {viewAccount.package
                          ? viewAccount.package.package_size
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Payment Type" span={1.5}>
                        {viewAccount.package
                          ? viewAccount.package.payment_type
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Amount to be Collected"
                        span={1.5}
                      >
                        {viewAccount.package
                          ? viewAccount.package.total_price
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Declared Value" span={1.5}>
                        {viewAccount.package
                          ? viewAccount.package.declared_value
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Length" span={1.5}>
                        {viewAccount.package ? viewAccount.package.length : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Width" span={1.5}>
                        {viewAccount.package ? viewAccount.package.width : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Height" span={1.5}>
                        {viewAccount.package ? viewAccount.package.height : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label="Weight" span={1.5}>
                        {viewAccount.package ? viewAccount.package.weight : ''}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <Spacer />
                  <Button
                    type="primary"
                    block
                    onClick={() => history.push(
                      `/waybill/edit/${
                        viewAccount.package
                          ? viewAccount.package.tracking_number
                          : ''
                      }`,
                    )
                    }
                  >
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </Icon>
                    Edit
                  </Button>
                </Col>
              </Row>
            </Card>
          </Spin>
          <Spacer />
          <Card
            title="Item List"
            extra={`Total: ${tnumberItems.length}`}
          >
            <Spin spinning={loadSpinItem} tip="Loading package items...">
              <ReactTable
                data={tnumberItems}
                columns={[
                  {
                    Header: 'Reference',
                    sortable: true,
                    filterable: true,
                    accessor: 'reference',
                  },
                  {
                    Header: 'Description',
                    sortable: true,
                    filterable: true,
                    accessor: 'description',
                  },
                  {
                    Header: 'Status',
                    sortable: true,
                    filterable: true,
                    accessor: 'status',
                  },
                  {
                    Header: 'Type',
                    sortable: true,
                    filterable: true,
                    accessor: 'type',
                  },
                  {
                    Header: 'Quantity',
                    sortable: true,
                    filterable: true,
                    accessor: 'quantity',
                  },
                  {
                    Header: 'Handover Quantity',
                    sortable: true,
                    filterable: true,
                    accessor: 'handover_quantity',
                  },
                  {
                    Header: 'Close Quantity',
                    sortable: true,
                    filterable: true,
                    Cell: row => (
                      <p>{parseInt(row.original.delivered_quantity, 10) + parseInt(row.original.returned_quantity, 10) + parseInt(row.original.claims_quantity, 10)}</p>
                    ),
                  },
                  {
                    Header: 'Length',
                    sortable: true,
                    filterable: true,
                    accessor: 'length',
                  },
                  {
                    Header: 'UOM',
                    sortable: true,
                    filterable: true,
                    accessor: 'uom',
                  },
                  {
                    Header: 'Width',
                    sortable: true,
                    filterable: true,
                    accessor: 'width',
                  },
                  {
                    Header: 'Remarks',
                    sortable: true,
                    filterable: true,
                    accessor: 'comment',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                      <center>
                        <Button
                          type="link"
                          onClick={() => this.openViewModal(row.original)}
                        >
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </Icon>
                          view
                        </Button>
                      </center>
                    ),
                  },
                ]}
              />
            </Spin>
          </Card>
          <Spacer />
          <Row>
            <Col xs={24} sm={24} lg={18}>
              <Card
                title="Transaction History"
                extra={`Total: ${
                  transactionHistory ? transactionHistory.length : 0
                }`}
              >
                <Spin spinning={fetchingPackageStatus} tip="Loading transaction history...">
                  <ReactTable
                    data={transactionHistory}
                    columns={[
                      {
                        Header: 'Date',
                        Cell: row => (
                          <p>{row.original.created_at}</p>
                        ),
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                      },
                      {
                        Header: 'Reference',
                        accessor: 'reference',
                      },
                      {
                        Header: 'Remarks',
                        accessor: 'remarks',
                      },
                      {
                        Header: 'Updated By',
                        accessor: 'updated_by',
                      },
                    ]}
                  />
                </Spin>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={6}>
              {viewAccount.package ? (
                viewAccount.package.signature ? (
                  <React.Fragment>
                    <Card title="e-PoD">
                      <img
                        alt="example"
                        style={{ width: '100%', marginTop: 0 }}
                        src={
                          viewAccount.package
                          && viewAccount.package.signature !== null
                            ? `data:image/png;base64, ${viewAccount.package.signature}`
                            : imageHandler
                        }
                      />
                      <Button
                        block
                        onClick={() => {
                          const file = new Blob(
                            [viewAccount.package.signature],
                            { type: 'application/octet-stream' },
                          );
                          fileDownload(file, 'ePOD.png');
                          // window.location.href = `data:image/png;base64, ${viewAccount.package.signature}`;
                          // viewAccount.package.signature
                        }}
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                        Download EPOD
                      </Button>
                    </Card>
                    <div style={{ marginTop: 10 }} />
                  </React.Fragment>
                ) : null
              ) : null}
              {viewAccount.package ? (
                viewAccount.package.pod_url ? (
                  <Card title="Actual PoD">
                    <img
                      alt="example"
                      style={{ width: '100%', marginTop: 0 }}
                      src={
                        viewAccount.package
                        && viewAccount.package.pod_url !== null
                          ? viewAccount.package.pod_url
                          : imageHandler
                      }
                    />
                    <Button
                      block
                      onClick={() => {
                        window.open(viewAccount.package.pod_url);
                        // viewAccount.package.pod_url
                      }}
                    >
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPrint} fixedWidth />
                      </Icon>
                      Download POD
                    </Button>
                  </Card>
                ) : null
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  viewAccount: state.waybill.views,
});

const mapDispatchToProps = {
  waybillComponent: waybill,
  doWaybillItemList: waybillItemList,
  doFetchWaybillTransactionHistory: fetchWaybillTransactionHistory,
};

Waybill.propTypes = {
  viewAccount: PropTypes.oneOfType([PropTypes.object]).isRequired,
  waybillComponent: PropTypes.func.isRequired,
  doWaybillItemList: PropTypes.func.isRequired,
  doFetchWaybillTransactionHistory: PropTypes.func.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Waybill);
