import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Legacy
import waybill from '../pages/waybill/ducks';

import auth from '../pages/auth/ducks';
import tools from '../pages/tools/ducks';
import merchantPickup from '../pages/merchantPickup/newDucks';

export const history = createBrowserHistory();
const router = connectRouter(history);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'waybill'],
};

export default persistReducer(
  persistConfig,
  combineReducers({
    waybill,
    tools,
    merchantPickup,
    router,
    auth,
  }),
);
