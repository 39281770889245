import roles from '../services/roles';

import WaybillView from '../pages/waybill/WaybillView';

import DimweightUpload from '../pages/tools/DimweightUpload';

import ContentMissing from '../pages/errors/404';

import WaybillCreate from '../pages/waybill/WaybillCreate';
import WaybillEdit from '../pages/waybill/WaybillEdit';
import WaybillList from '../pages/waybill/WaybillList';
import WaybillUpload from '../pages/waybill/WaybillUpload';

import ItemsList from '../pages/item/ItemList';
import ItemListView from '../pages/item/ItemListView';
import ItemListViewByReference from '../pages/item/ItemListViewByReference';
import BarcodeGenerator from '../pages/item/Barcode';

// Packages - Void waybill
import VoidWaybill from '../pages/voidWaybill/VoidWaybill';

import MerchantPickupList from '../pages/merchantPickup/MerchantPickupList';
import MerchantPickupView from '../pages/merchantPickup/MerchantPickupView';
import MerchantListSelector from '../pages/merchantPickup/MerchantListSelector';

// Tools - Package Group Checker
import GroupChecker from '../pages/tools/GroupChecker';

export default [
  {
    key: '/',
    roles: roles.all,
    exact: true,
    path: '/',
    component: WaybillList,
  },
  {
    key: '/waybill/create',
    roles: roles.all,
    exact: true,
    path: '/waybill/create',
    component: WaybillCreate,
  },
  {
    key: '/waybill/edit/:id',
    roles: roles.all,
    exact: true,
    path: '/waybill/edit/:id',
    component: WaybillEdit,
  },
  {
    key: '/waybill/list',
    roles: roles.all,
    exact: true,
    path: '/waybill/list',
    component: WaybillList,
  },
  {
    key: '/waybill/upload',
    roles: roles.all,
    exact: true,
    path: '/waybill/upload',
    component: WaybillUpload,
  },
  {
    key: '/waybill/:tnumber',
    roles: roles.all,
    exact: true,
    path: '/waybill/:tnumber',
    component: WaybillView,
  },
  {
    key: '/items',
    roles: roles.all,
    exact: true,
    path: '/items',
    component: ItemsList,
  },
  {
    key: '/items/:reference/:item_id',
    roles: roles.all,
    exact: true,
    path: '/items/:reference/:item_id',
    component: ItemListView,
  },
  {
    key: '/items/:reference',
    roles: roles.all,
    exact: true,
    path: '/items/:reference',
    component: ItemListViewByReference,
  },
  {
    key: '/dimweight',
    roles: roles.all,
    exact: true,
    path: '/dimweight',
    component: DimweightUpload,
  },
  {
    key: '/barcode',
    roles: roles.all,
    exact: true,
    path: '/barcode',
    component: BarcodeGenerator,
  },
  {
    key: '/void-waybill',
    roles: roles.all,
    exact: true,
    path: '/void-waybill',
    component: VoidWaybill,
  },
  {
    key: '/tools/package-group-checker',
    roles: roles.all,
    exact: true,
    path: '/tools/package-group-checker',
    component: GroupChecker,
  },
  {
    key: '/merchant-pickup/selector',
    roles: roles.all,
    exact: true,
    path: '/merchant-pickup/selector',
    component: MerchantListSelector,
  },
  // {
  //   key: '/merchant-pickup/list',
  //   roles: roles.all,
  //   exact: true,
  //   path: '/merchant-pickup/list',
  //   component: MerchantPickupList,
  // },
  // {
  //   key: '/merchant-pickup/:id',
  //   roles: roles.all,
  //   exact: true,
  //   path: '/merchant-pickup/:id',
  //   component: MerchantPickupView,
  // },
  {
    key: '/404',
    roles: roles.all,
    exact: true,
    path: '/404',
    component: ContentMissing,
  },
];
