import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { history } from './reducer';
import Routes from './Routes';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ConnectedRouter history={history}>
          <Switch>
            <Routes />
          </Switch>
        </ConnectedRouter>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
