import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const TestRoute = (props) => {
  const { component: Component, ...rest } = props;
  return <Route {...rest} render={componentProps => <Component {...componentProps} />} />;
};

TestRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default TestRoute;
