import React, { Component } from 'react';
import Select from 'react-select/creatable';

export class CreatableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const customStyles = {
      container: provided => ({
        ...provided,
        minHeight: '1px',
        textAlign: 'left',
        border: 'none',
        width: '100%',
        paddingTop: '4px',
      }),
      control: provided => ({
        ...provided,
        minHeight: '1px',
        height: '32px',
        '&:hover': {
          borderColor: '#F48C45',
          cursor: 'pointer',
        },
      }),
      dropdownIndicator: provided => ({
        ...provided,
        minHeight: '1px',
        color: '#ECECEC',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      valueContainer: provided => ({
        ...provided,
        minHeight: '1px',
        height: '35px',
      }),
      singleValue: provided => ({
        ...provided,
        minHeight: '1px',
        height: '35px',
        color: '#848684',
      }),
      placeholder: provided => ({
        ...provided,
        color: '#C3C2C1',
        paddingBottom: '7px',
      }),
    };
    return (
      <Select styles={customStyles} {...this.props} />
    );
  }
}

export default CreatableSelect;
